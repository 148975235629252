import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTabletQuery } from 'common-front/src/hooks/useTabletQuery';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Link } from 'common/src/designSystem/components/link';
import { Delegation } from 'common/src/generated/types';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import * as React from 'react';

interface IDelegationsListDelegationProps {
    delegation: Pick<Delegation, 'id' | 'name' | 'eventId'>;
}

export const DelegationsListDelegation = ({ delegation }: IDelegationsListDelegationProps) => {
    const {
        params: { organizationId },
        translate
    } = useHeavent();
    const isTablet = useTabletQuery();

    return (
        <Link
            to={DelegationsPaths.DELEGATION({
                delegationId: delegation.id,
                eventId: delegation.eventId || undefined,
                organizationId
            })}
        >
            <Flex
                align="center"
                css={{
                    background: 'white',
                    bd: true,
                    padding: '$4 $6'
                }}
                gap="3"
            >
                <Box css={{ flex: '1' }} font="gray800 textSm medium">
                    {delegation.name}
                </Box>

                {isTablet ? (
                    <Button color="white" size="sm">
                        {translate('voir_cette_d_l_96190')}
                    </Button>
                ) : (
                    <Button color="white" leftIcon="arrow-right" size="sm" />
                )}
            </Flex>
        </Link>
    );
};
