// /!\ THIS FILE IS AUTOMATICALLY GENERATED, DO NOT EDIT /!\
/* tslint:disable */
import { executeQuery } from 'common-front/src/components/graphql/graphql';
import {
    IUseMutationOptions,
    IUseMutationReturns,
    IUseQueryReturns
} from 'common/src/util/graphql/types';
import { useMutation, useQuery } from 'common/src/util/dependencies/dependencies';

import UserExists from '../v2/auth/userExists.graphql';
import { UserExistsQuery, UserExistsQueryVariables } from 'common/src/generated/types';
import DelegationSpaceDashboard from '../v2/delegations/dashboard/delegationSpaceDashboard.graphql';
import {
    DelegationSpaceDashboardQuery,
    DelegationSpaceDashboardQueryVariables
} from 'common/src/generated/types';
import DelegationsSpaceDelegation from '../v2/delegations/delegationsSpaceDelegation.graphql';
import {
    DelegationsSpaceDelegationQuery,
    DelegationsSpaceDelegationQueryVariables
} from 'common/src/generated/types';
import DelegationUpdateFields from '../v2/delegations/fields/update/delegationUpdateFields.graphql';
import {
    DelegationUpdateFieldsMutation,
    DelegationUpdateFieldsMutationVariables
} from 'common/src/generated/types';
import DelegationUpdateFieldsInfos from '../v2/delegations/fields/update/delegationUpdateFieldsInfos.graphql';
import {
    DelegationUpdateFieldsInfosQuery,
    DelegationUpdateFieldsInfosQueryVariables
} from 'common/src/generated/types';
import DelegationsList from '../v2/delegations/list/delegationsList.graphql';
import { DelegationsListQuery, DelegationsListQueryVariables } from 'common/src/generated/types';
import ParentUsersInfos from '../v2/delegations/members/create/parentUsersInfos.graphql';
import { ParentUsersInfosQuery, ParentUsersInfosQueryVariables } from 'common/src/generated/types';
import UsersInfosDelegationAdd from '../v2/delegations/members/create/usersInfosDelegationAdd.graphql';
import {
    UsersInfosDelegationAddMutation,
    UsersInfosDelegationAddMutationVariables
} from 'common/src/generated/types';
import DelegationsSpaceCreateMember from '../v2/delegations/members/update/delegationsSpaceCreateMember.graphql';
import {
    DelegationsSpaceCreateMemberQuery,
    DelegationsSpaceCreateMemberQueryVariables
} from 'common/src/generated/types';
import DelegationsSpaceUpdateMember from '../v2/delegations/members/update/delegationsSpaceUpdateMember.graphql';
import {
    DelegationsSpaceUpdateMemberQuery,
    DelegationsSpaceUpdateMemberQueryVariables
} from 'common/src/generated/types';
import FormAccreditations from '../v2/forms/register/accreditations/formAccreditations.graphql';
import {
    FormAccreditationsQuery,
    FormAccreditationsQueryVariables
} from 'common/src/generated/types';
import FormInfos from '../v2/forms/register/formInfos.graphql';
import { FormInfosQuery, FormInfosQueryVariables } from 'common/src/generated/types';
import FormRegister from '../v2/forms/register/formRegister.graphql';
import { FormRegisterMutation, FormRegisterMutationVariables } from 'common/src/generated/types';
import FormMissions from '../v2/forms/register/missions/formMissions.graphql';
import { FormMissionsQuery, FormMissionsQueryVariables } from 'common/src/generated/types';
import FormSlots from '../v2/forms/register/slots/formSlots.graphql';
import { FormSlotsQuery, FormSlotsQueryVariables } from 'common/src/generated/types';
import EmailFormInviteSend from '../v2/forms/register/success/emailFormInviteSend.graphql';
import {
    EmailFormInviteSendMutation,
    EmailFormInviteSendMutationVariables
} from 'common/src/generated/types';
import FormTeamSummary from '../v2/forms/register/teamSummary/formTeamSummary.graphql';
import { FormTeamSummaryQuery, FormTeamSummaryQueryVariables } from 'common/src/generated/types';
import UsersInfos from '../v2/forms/register/usersInfos.graphql';
import { UsersInfosQuery, UsersInfosQueryVariables } from 'common/src/generated/types';
import MemberCampaigns from '../v2/members/campaigns/memberCampaigns.graphql';
import { MemberCampaignsQuery, MemberCampaignsQueryVariables } from 'common/src/generated/types';
import MemberDocuments from '../v2/members/documents/memberDocuments.graphql';
import { MemberDocumentsQuery, MemberDocumentsQueryVariables } from 'common/src/generated/types';
import Member from '../v2/members/member.graphql';
import { MemberQuery, MemberQueryVariables } from 'common/src/generated/types';
import MemberRegistration from '../v2/members/registrations/memberRegistration.graphql';
import {
    MemberRegistrationQuery,
    MemberRegistrationQueryVariables
} from 'common/src/generated/types';
import UpdateMemberInfos from '../v2/members/update/updateMemberInfos.graphql';
import {
    UpdateMemberInfosQuery,
    UpdateMemberInfosQueryVariables
} from 'common/src/generated/types';
import OrganizationPublic from '../v2/organizationPublic.graphql';
import {
    OrganizationPublicQuery,
    OrganizationPublicQueryVariables
} from 'common/src/generated/types';
import Profiles from '../v2/profiles/list/profiles.graphql';
import { ProfilesQuery, ProfilesQueryVariables } from 'common/src/generated/types';

import { Fragments } from 'common/src/generated/fragments';
import { IFragment } from 'common/src/generated/fragments';

export interface IQueryFragments {
    query: string;
    fragments: IFragment[];
}

export const Queries = {
    UserExists,
    DelegationSpaceDashboard,
    DelegationsSpaceDelegation,
    DelegationUpdateFieldsInfos,
    DelegationsList,
    ParentUsersInfos,
    DelegationsSpaceCreateMember,
    DelegationsSpaceUpdateMember,
    FormAccreditations,
    FormInfos,
    FormMissions,
    FormSlots,
    FormTeamSummary,
    UsersInfos,
    MemberCampaigns,
    MemberDocuments,
    Member,
    MemberRegistration,
    UpdateMemberInfos,
    OrganizationPublic,
    Profiles
};

export const Mutations = {
    DelegationUpdateFields,
    UsersInfosDelegationAdd,
    FormRegister,
    EmailFormInviteSend
};

export function useUserExistsQuery(
    args: UserExistsQueryVariables
): IUseQueryReturns<UserExistsQuery> {
    return useQuery(Queries.UserExists, args, []);
}

export function executeUserExistsQuery(
    args: UserExistsQueryVariables,
    token: string | null | undefined
): Promise<UserExistsQuery> {
    return executeQuery<UserExistsQuery>(Queries.UserExists, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useDelegationSpaceDashboardQuery(
    args: DelegationSpaceDashboardQueryVariables
): IUseQueryReturns<DelegationSpaceDashboardQuery> {
    return useQuery(Queries.DelegationSpaceDashboard, args, [
        Fragments.CustomFieldWithConditionFragment,
        Fragments.MemberRegistrationUserInfoFragment
    ]);
}

export function executeDelegationSpaceDashboardQuery(
    args: DelegationSpaceDashboardQueryVariables,
    token: string | null | undefined
): Promise<DelegationSpaceDashboardQuery> {
    return executeQuery<DelegationSpaceDashboardQuery>(Queries.DelegationSpaceDashboard, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CustomFieldWithConditionFragment,
            Fragments.MemberRegistrationUserInfoFragment
        ]
    });
}

export function useDelegationsSpaceDelegationQuery(
    args: DelegationsSpaceDelegationQueryVariables
): IUseQueryReturns<DelegationsSpaceDelegationQuery> {
    return useQuery(Queries.DelegationsSpaceDelegation, args, [
        Fragments.OrganizationDelegationsSpaceFragment,
        Fragments.OrganizationCustomFieldsFragment,
        Fragments.MemberRegistrationUserInfoFragment
    ]);
}

export function executeDelegationsSpaceDelegationQuery(
    args: DelegationsSpaceDelegationQueryVariables,
    token: string | null | undefined
): Promise<DelegationsSpaceDelegationQuery> {
    return executeQuery<DelegationsSpaceDelegationQuery>(Queries.DelegationsSpaceDelegation, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.OrganizationDelegationsSpaceFragment,
            Fragments.OrganizationCustomFieldsFragment,
            Fragments.MemberRegistrationUserInfoFragment
        ]
    });
}

export function useDelegationUpdateFieldsMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<DelegationUpdateFieldsMutation, DelegationUpdateFieldsMutationVariables> {
    return useMutation(Mutations.DelegationUpdateFields, args);
}

export function useDelegationUpdateFieldsInfosQuery(
    args: DelegationUpdateFieldsInfosQueryVariables
): IUseQueryReturns<DelegationUpdateFieldsInfosQuery> {
    return useQuery(Queries.DelegationUpdateFieldsInfos, args, [
        Fragments.CustomFieldWithConditionFragment
    ]);
}

export function executeDelegationUpdateFieldsInfosQuery(
    args: DelegationUpdateFieldsInfosQueryVariables,
    token: string | null | undefined
): Promise<DelegationUpdateFieldsInfosQuery> {
    return executeQuery<DelegationUpdateFieldsInfosQuery>(Queries.DelegationUpdateFieldsInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CustomFieldWithConditionFragment]
    });
}

export function useDelegationsListQuery(
    args: DelegationsListQueryVariables
): IUseQueryReturns<DelegationsListQuery> {
    return useQuery(Queries.DelegationsList, args, []);
}

export function executeDelegationsListQuery(
    args: DelegationsListQueryVariables,
    token: string | null | undefined
): Promise<DelegationsListQuery> {
    return executeQuery<DelegationsListQuery>(Queries.DelegationsList, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useParentUsersInfosQuery(
    args: ParentUsersInfosQueryVariables
): IUseQueryReturns<ParentUsersInfosQuery> {
    return useQuery(Queries.ParentUsersInfos, args, [Fragments.ExistingVolunteerFragment]);
}

export function executeParentUsersInfosQuery(
    args: ParentUsersInfosQueryVariables,
    token: string | null | undefined
): Promise<ParentUsersInfosQuery> {
    return executeQuery<ParentUsersInfosQuery>(Queries.ParentUsersInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.ExistingVolunteerFragment]
    });
}

export function useUsersInfosDelegationAddMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UsersInfosDelegationAddMutation, UsersInfosDelegationAddMutationVariables> {
    return useMutation(Mutations.UsersInfosDelegationAdd, args);
}

export function useDelegationsSpaceCreateMemberQuery(
    args: DelegationsSpaceCreateMemberQueryVariables
): IUseQueryReturns<DelegationsSpaceCreateMemberQuery> {
    return useQuery(Queries.DelegationsSpaceCreateMember, args, [
        Fragments.OrganizationDelegationsSpaceFragment,
        Fragments.DelegationAccreditationSlotFragment,
        Fragments.FormMissionsOptionsFragment,
        Fragments.FormAccreditationsOptionsFragment,
        Fragments.FormElementDisplayFragment,
        Fragments.FormRegisterCustomSlotPeriodFragment,
        Fragments.FormRegisterAccreditationCategoryFragment,
        Fragments.FormRegisterCategoryFragment
    ]);
}

export function executeDelegationsSpaceCreateMemberQuery(
    args: DelegationsSpaceCreateMemberQueryVariables,
    token: string | null | undefined
): Promise<DelegationsSpaceCreateMemberQuery> {
    return executeQuery<DelegationsSpaceCreateMemberQuery>(Queries.DelegationsSpaceCreateMember, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.OrganizationDelegationsSpaceFragment,
            Fragments.DelegationAccreditationSlotFragment,
            Fragments.FormMissionsOptionsFragment,
            Fragments.FormAccreditationsOptionsFragment,
            Fragments.FormElementDisplayFragment,
            Fragments.FormRegisterCustomSlotPeriodFragment,
            Fragments.FormRegisterAccreditationCategoryFragment,
            Fragments.FormRegisterCategoryFragment
        ]
    });
}

export function useDelegationsSpaceUpdateMemberQuery(
    args: DelegationsSpaceUpdateMemberQueryVariables
): IUseQueryReturns<DelegationsSpaceUpdateMemberQuery> {
    return useQuery(Queries.DelegationsSpaceUpdateMember, args, [
        Fragments.OrganizationDelegationsSpaceFragment,
        Fragments.UserInfoFormFragment,
        Fragments.DelegationAccreditationSlotFragment,
        Fragments.FormMissionsOptionsFragment,
        Fragments.FormAccreditationsOptionsFragment,
        Fragments.FormElementDisplayFragment,
        Fragments.FormRegisterCustomSlotPeriodFragment,
        Fragments.FormRegisterAccreditationCategoryFragment,
        Fragments.FormRegisterCategoryFragment
    ]);
}

export function executeDelegationsSpaceUpdateMemberQuery(
    args: DelegationsSpaceUpdateMemberQueryVariables,
    token: string | null | undefined
): Promise<DelegationsSpaceUpdateMemberQuery> {
    return executeQuery<DelegationsSpaceUpdateMemberQuery>(Queries.DelegationsSpaceUpdateMember, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.OrganizationDelegationsSpaceFragment,
            Fragments.UserInfoFormFragment,
            Fragments.DelegationAccreditationSlotFragment,
            Fragments.FormMissionsOptionsFragment,
            Fragments.FormAccreditationsOptionsFragment,
            Fragments.FormElementDisplayFragment,
            Fragments.FormRegisterCustomSlotPeriodFragment,
            Fragments.FormRegisterAccreditationCategoryFragment,
            Fragments.FormRegisterCategoryFragment
        ]
    });
}

export function useFormAccreditationsQuery(
    args: FormAccreditationsQueryVariables
): IUseQueryReturns<FormAccreditationsQuery> {
    return useQuery(Queries.FormAccreditations, args, [
        Fragments.FormRegisterAccreditationCategoryFragment
    ]);
}

export function executeFormAccreditationsQuery(
    args: FormAccreditationsQueryVariables,
    token: string | null | undefined
): Promise<FormAccreditationsQuery> {
    return executeQuery<FormAccreditationsQuery>(Queries.FormAccreditations, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.FormRegisterAccreditationCategoryFragment]
    });
}

export function useFormInfosQuery(args: FormInfosQueryVariables): IUseQueryReturns<FormInfosQuery> {
    return useQuery(Queries.FormInfos, args, [
        Fragments.FormMissionsOptionsFragment,
        Fragments.FormAccreditationsOptionsFragment,
        Fragments.FormElementDisplayFragment
    ]);
}

export function executeFormInfosQuery(
    args: FormInfosQueryVariables,
    token: string | null | undefined
): Promise<FormInfosQuery> {
    return executeQuery<FormInfosQuery>(Queries.FormInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.FormMissionsOptionsFragment,
            Fragments.FormAccreditationsOptionsFragment,
            Fragments.FormElementDisplayFragment
        ]
    });
}

export function useFormRegisterMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<FormRegisterMutation, FormRegisterMutationVariables> {
    return useMutation(Mutations.FormRegister, args);
}

export function useFormMissionsQuery(
    args: FormMissionsQueryVariables
): IUseQueryReturns<FormMissionsQuery> {
    return useQuery(Queries.FormMissions, args, [Fragments.FormRegisterCategoryFragment]);
}

export function executeFormMissionsQuery(
    args: FormMissionsQueryVariables,
    token: string | null | undefined
): Promise<FormMissionsQuery> {
    return executeQuery<FormMissionsQuery>(Queries.FormMissions, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.FormRegisterCategoryFragment]
    });
}

export function useFormSlotsQuery(args: FormSlotsQueryVariables): IUseQueryReturns<FormSlotsQuery> {
    return useQuery(Queries.FormSlots, args, [Fragments.FormRegisterCustomSlotPeriodFragment]);
}

export function executeFormSlotsQuery(
    args: FormSlotsQueryVariables,
    token: string | null | undefined
): Promise<FormSlotsQuery> {
    return executeQuery<FormSlotsQuery>(Queries.FormSlots, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.FormRegisterCustomSlotPeriodFragment]
    });
}

export function useEmailFormInviteSendMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EmailFormInviteSendMutation, EmailFormInviteSendMutationVariables> {
    return useMutation(Mutations.EmailFormInviteSend, args);
}

export function useFormTeamSummaryQuery(
    args: FormTeamSummaryQueryVariables
): IUseQueryReturns<FormTeamSummaryQuery> {
    return useQuery(Queries.FormTeamSummary, args, []);
}

export function executeFormTeamSummaryQuery(
    args: FormTeamSummaryQueryVariables,
    token: string | null | undefined
): Promise<FormTeamSummaryQuery> {
    return executeQuery<FormTeamSummaryQuery>(Queries.FormTeamSummary, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUsersInfosQuery(
    args: UsersInfosQueryVariables
): IUseQueryReturns<UsersInfosQuery> {
    return useQuery(Queries.UsersInfos, args, [Fragments.UserInfoFormFragment]);
}

export function executeUsersInfosQuery(
    args: UsersInfosQueryVariables,
    token: string | null | undefined
): Promise<UsersInfosQuery> {
    return executeQuery<UsersInfosQuery>(Queries.UsersInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.UserInfoFormFragment]
    });
}

export function useMemberCampaignsQuery(
    args: MemberCampaignsQueryVariables
): IUseQueryReturns<MemberCampaignsQuery> {
    return useQuery(Queries.MemberCampaigns, args, [Fragments.OrganizationMembersSpaceFragment]);
}

export function executeMemberCampaignsQuery(
    args: MemberCampaignsQueryVariables,
    token: string | null | undefined
): Promise<MemberCampaignsQuery> {
    return executeQuery<MemberCampaignsQuery>(Queries.MemberCampaigns, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.OrganizationMembersSpaceFragment]
    });
}

export function useMemberDocumentsQuery(
    args: MemberDocumentsQueryVariables
): IUseQueryReturns<MemberDocumentsQuery> {
    return useQuery(Queries.MemberDocuments, args, [Fragments.OrganizationMembersSpaceFragment]);
}

export function executeMemberDocumentsQuery(
    args: MemberDocumentsQueryVariables,
    token: string | null | undefined
): Promise<MemberDocumentsQuery> {
    return executeQuery<MemberDocumentsQuery>(Queries.MemberDocuments, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.OrganizationMembersSpaceFragment]
    });
}

export function useMemberQuery(args: MemberQueryVariables): IUseQueryReturns<MemberQuery> {
    return useQuery(Queries.Member, args, [Fragments.OrganizationMembersSpaceFragment]);
}

export function executeMemberQuery(
    args: MemberQueryVariables,
    token: string | null | undefined
): Promise<MemberQuery> {
    return executeQuery<MemberQuery>(Queries.Member, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.OrganizationMembersSpaceFragment]
    });
}

export function useMemberRegistrationQuery(
    args: MemberRegistrationQueryVariables
): IUseQueryReturns<MemberRegistrationQuery> {
    return useQuery(Queries.MemberRegistration, args, [
        Fragments.OrganizationMembersSpaceFragment,
        Fragments.CustomFieldWithConditionFragment,
        Fragments.MemberRegistrationUserInfoFragment,
        Fragments.FormsUsersInfosWishedFragment,
        Fragments.MemberAssignmentFragment,
        Fragments.MemberAccreditationFragment
    ]);
}

export function executeMemberRegistrationQuery(
    args: MemberRegistrationQueryVariables,
    token: string | null | undefined
): Promise<MemberRegistrationQuery> {
    return executeQuery<MemberRegistrationQuery>(Queries.MemberRegistration, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.OrganizationMembersSpaceFragment,
            Fragments.CustomFieldWithConditionFragment,
            Fragments.MemberRegistrationUserInfoFragment,
            Fragments.FormsUsersInfosWishedFragment,
            Fragments.MemberAssignmentFragment,
            Fragments.MemberAccreditationFragment
        ]
    });
}

export function useUpdateMemberInfosQuery(
    args: UpdateMemberInfosQueryVariables
): IUseQueryReturns<UpdateMemberInfosQuery> {
    return useQuery(Queries.UpdateMemberInfos, args, [
        Fragments.OrganizationMembersSpaceFragment,
        Fragments.UpdateUserFormFragment
    ]);
}

export function executeUpdateMemberInfosQuery(
    args: UpdateMemberInfosQueryVariables,
    token: string | null | undefined
): Promise<UpdateMemberInfosQuery> {
    return executeQuery<UpdateMemberInfosQuery>(Queries.UpdateMemberInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.OrganizationMembersSpaceFragment, Fragments.UpdateUserFormFragment]
    });
}

export function useOrganizationPublicQuery(
    args: OrganizationPublicQueryVariables
): IUseQueryReturns<OrganizationPublicQuery> {
    return useQuery(Queries.OrganizationPublic, args, []);
}

export function executeOrganizationPublicQuery(
    args: OrganizationPublicQueryVariables,
    token: string | null | undefined
): Promise<OrganizationPublicQuery> {
    return executeQuery<OrganizationPublicQuery>(Queries.OrganizationPublic, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useProfilesQuery(args: ProfilesQueryVariables): IUseQueryReturns<ProfilesQuery> {
    return useQuery(Queries.Profiles, args, []);
}

export function executeProfilesQuery(
    args: ProfilesQueryVariables,
    token: string | null | undefined
): Promise<ProfilesQuery> {
    return executeQuery<ProfilesQuery>(Queries.Profiles, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}
