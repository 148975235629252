import { Form as FinalForm } from 'common-front/src/components/form/form';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormElementType,
    FormInfosQuery,
    FormRegisterResult,
    RegisterAccreditationDisplay,
    RegisterPositionDisplay,
    RegisterSlotDisplay,
    SupportedLanguage,
    TeamCode,
    UserEmail,
    UserInfoFormFragment,
    UsersInfoId
} from 'common/src/generated/types';
import { FormRegisterInputService, IFormRegisterValues } from 'common/src/input/formRegisterInput';
import { FieldFormElement } from 'common/src/input/userInfoInput';
import { TemplateService } from 'common/src/services/templateService';
import { ValidateService } from 'common/src/services/validateService';
import { useParams, useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { Fields } from 'common/src/vo/field';
import { FormPreviewState } from 'common/src/vo/form';
import { DateTime } from 'luxon';
import * as React from 'react';
import {
    executeUsersInfosQuery,
    useFormInfosQuery,
    useFormRegisterMutation
} from '../../../generated/graphqlHooks';
import { addCustomTheme } from '../../addCustomTheme';
import { FormAccreditationsLoader } from './accreditations/formAccreditationsLoader';
import { FormBox } from './formBox';
import { FormClosed } from './formClosed';
import { FormContainer } from './formContainer';
import { FormEmail } from './formEmail';
import { FormInformations } from './formInformations';
import { FormLanguages } from './formLanguages';
import { FormNoAccess } from './formNoAccess';
import { FormProfiles } from './formProfiles';
import { FormMissionsLoader } from './missions/formMissionsLoader';
import { FormSlotsLoader } from './slots/formSlotsLoader';
import { FormSuccess } from './success/formSuccess';
import { FormTeamSummary } from './teamSummary/formTeamSummary';

interface IFormContentProps {
    countryCode: Emptyable<string>;
    event?: FormInfosQuery['organization']['event'];
    form: FormInfosQuery['organization']['form'];
    formPreviewState?: FormPreviewState;
    hideFormLanguages: boolean;
    isPreview: boolean;
    languages: SupportedLanguage[];
    team: Emptyable<NonNullable<FormInfosQuery['organization']['event']>['team']>;
}

const FormContent = (props: IFormContentProps) => {
    const {
        translate,
        params: { organizationId, eventId, delegationId, formId }
    } = useHeavent();
    const { mutate: formRegister } = useFormRegisterMutation();
    const validateService = useService(ValidateService);
    const formRegisterInput = useService(FormRegisterInputService);
    const templateService = useService(TemplateService);
    const formContainerRef = React.useRef<HTMLDivElement>(null);
    const resetScroll = React.useCallback(() => {
        if (formContainerRef.current) {
            formContainerRef.current.scrollTop = 0;
        }
    }, [formContainerRef]);
    const fieldFormElements = React.useMemo(
        () =>
            props.form.elements.filter(
                (e) => e.elementType === FormElementType.Field
            ) as FieldFormElement[],
        [props.form]
    );
    const customFields = React.useMemo(
        () => fieldFormElements.map((element) => element.customField!),
        [fieldFormElements]
    );
    const [currentEmail, setEmail] = React.useState<string | undefined>(undefined);
    const [currentToken, setToken] = React.useState<string | undefined>(undefined);
    const [hasAccess, setHasAccess] = React.useState(props.form.segments.length === 0);
    const [initialValues, setInitialValues] = React.useState<IFormRegisterValues>({
        formRegister: formRegisterInput.formRegisterInputDefault(null, customFields, {
            countryCode: props.countryCode,
            eventStartDate: props.event?.startAt,
            forOther: false,
            sendNotificationEmail: true,
            slotDisplay: props.form.slotDisplay,
            team: props.team
        })
    });
    const [step, setStep] = React.useState(0);
    const [userInfo, setUserInfo] = React.useState<Emptyable<UserInfoFormFragment>>(null);
    const [usersInfos, setUsersInfos] = React.useState<Array<UserInfoFormFragment>>([]);
    const [formRegisterResult, setFormRegisterResult] =
        React.useState<Emptyable<FormRegisterResult>>(null);
    const renderText = React.useCallback(
        (message: string, fields: Fields) => {
            if (userInfo) {
                return templateService.renderText(
                    message,
                    userInfo
                        ? {
                              ...userInfo,
                              fields: { ...userInfo.fields, ...fields }
                          }
                        : {
                              id: -1 as UsersInfoId,
                              email: currentEmail!,
                              fields,
                              registrationDate: DateTime.now()
                          },
                    null,
                    props.event,
                    customFields,
                    null
                );
            } else {
                return message;
            }
        },
        [props.event, currentEmail, userInfo, customFields]
    );
    const onEmailSuccess = React.useCallback(
        async (email: string, token?: string) => {
            if (!props.isPreview) {
                if (token) {
                    const { user } = await executeUsersInfosQuery(
                        { organizationId, eventId, formId },
                        token
                    );

                    if (props.form.allowMultiProfiles) {
                        setUserInfo(user.userInfo);
                        setUsersInfos(user.usersInfos);
                    } else {
                        setUserInfo(user.userInfo);
                        setHasAccess(user.userInfo.canAccess);
                        setInitialValues({
                            formRegister: formRegisterInput.formRegisterInputDefault(
                                user.userInfo,
                                customFields,
                                {
                                    countryCode: props.countryCode,
                                    eventStartDate: props.event?.startAt,
                                    forOther: false,
                                    sendNotificationEmail: true,
                                    slotDisplay: props.form.slotDisplay,
                                    team: props.team
                                }
                            )
                        });
                    }
                }

                setEmail(email);
                setToken(token);

                setTimeout(() => {
                    setStep(step + 1);
                    resetScroll();
                }, 100);
            }
        },
        [step, customFields, props.form, props.countryCode, props.team, resetScroll]
    );
    const onProfilesSuccess = React.useCallback(
        (selectedUserInfoId: Emptyable<UsersInfoId>, forOther: boolean) => {
            if (!props.isPreview) {
                const selectedUserInfo = usersInfos.find((ui) => ui.id === selectedUserInfoId);

                setUserInfo(selectedUserInfo);
                setHasAccess(selectedUserInfo?.canAccess ?? props.form.segments.length === 0);
                setInitialValues({
                    formRegister: formRegisterInput.formRegisterInputDefault(
                        selectedUserInfo,
                        customFields,
                        {
                            countryCode: props.countryCode,
                            eventStartDate: props.event?.startAt,
                            forOther,
                            sendNotificationEmail: true,
                            slotDisplay: props.form.slotDisplay,
                            team: props.team
                        }
                    )
                });

                setTimeout(() => {
                    setStep(step + 1);
                    resetScroll();
                }, 100);
            }
        },
        [step, customFields, props.form, props.countryCode, props.team, resetScroll]
    );
    const onSubmit = React.useCallback(
        async (values: IFormRegisterValues) => {
            if (!props.isPreview) {
                if (step === numberOfSteps - 2) {
                    const result = await formRegister(
                        {
                            organizationId,
                            eventId,
                            delegationId,
                            formId,
                            email: currentEmail! as UserEmail,
                            formRegister: {
                                ...values.formRegister,
                                teamCode: props.team?.teamCode
                            }
                        },
                        currentToken
                    );

                    setFormRegisterResult(result.formRegister);
                }

                setStep(step + 1);
                resetScroll();
            }
        },
        [currentEmail, currentToken, step, setStep, resetScroll]
    );
    const isTeamRegistration = React.useMemo(
        () => props.form.areTeamsAllowed && isNonEmptyString(props.team?.teamCode),
        [props.form, props.team]
    );
    const showTeamSummary = React.useMemo(
        () => eventId && isTeamRegistration && !props.form.teamMemberFillFullForm,
        [eventId, isTeamRegistration, props.form]
    );
    const showProfiles = React.useMemo(
        () => props.form.allowMultiProfiles && usersInfos.length > 0,
        [props.form, usersInfos]
    );
    const showSlots = React.useMemo(
        () => props.form.slotDisplay !== RegisterSlotDisplay.Hide && eventId && !showTeamSummary,
        [props.form, eventId, showTeamSummary]
    );
    const showMissions = React.useMemo(
        () =>
            props.form.positionDisplay !== RegisterPositionDisplay.None &&
            eventId &&
            !showTeamSummary,
        [props.form, eventId, showTeamSummary]
    );
    const showAccreditations = React.useMemo(
        () =>
            props.form.accreditationDisplay !== RegisterAccreditationDisplay.None &&
            eventId &&
            !showTeamSummary,
        [props.form, eventId, showTeamSummary]
    );
    const numberOfSteps = React.useMemo(
        () =>
            3 +
            (showProfiles ? 1 : 0) +
            (showSlots ? 1 : 0) +
            (showMissions ? 1 : 0) +
            (showAccreditations ? 1 : 0) +
            (showTeamSummary ? 1 : 0),
        [showProfiles, showSlots, showMissions, showAccreditations, showTeamSummary]
    );
    const informationsStepIndex = showProfiles ? 2 : 1;
    const slotsStepIndex = showSlots ? informationsStepIndex + 1 : informationsStepIndex;
    const missionsStepIndex = showMissions ? slotsStepIndex + 1 : slotsStepIndex;
    const accreditationsStepIndex = showAccreditations ? missionsStepIndex + 1 : missionsStepIndex;
    const teamSummaryStepIndex = informationsStepIndex + 1;
    const validate = React.useCallback(
        (values: IFormRegisterValues) => {
            if (step === informationsStepIndex) {
                return validateService.validateForForm(
                    formRegisterInput.formRegisterInformationsSchema(fieldFormElements)
                )(values);
            } else if (showSlots && step === slotsStepIndex) {
                return validateService.validateForForm(
                    formRegisterInput.formRegisterSlotsSchema(
                        props.form.slotDisplay,
                        props.event!.startAt,
                        props.event!.endAt
                    )
                )(values);
            } else if (showMissions && step === missionsStepIndex) {
                return validateService.validateForForm(
                    formRegisterInput.formRegisterPositionsSchema(
                        props.form.positionRanking,
                        props.form.positionDisplay
                    )
                )(values);
            } else if (showAccreditations && step === accreditationsStepIndex) {
                return validateService.validateForForm(
                    formRegisterInput.formRegisterAccreditationsSchema(
                        props.form.accreditationDisplay
                    )
                );
            } else {
                return {};
            }
        },
        [
            fieldFormElements,
            step,
            props.form,
            informationsStepIndex,
            slotsStepIndex,
            missionsStepIndex
        ]
    );
    const registerButtonText = React.useMemo(
        () =>
            isNonEmptyString(props.form.registerButtonText)
                ? props.form.registerButtonText
                : translate('s_inscrire_20304'),
        [props.form]
    );
    const showFormLanguages = React.useMemo(
        () => !props.hideFormLanguages && props.languages.length > 1,
        [props.hideFormLanguages, props.languages]
    );

    return (
        <FormContainer ref={formContainerRef} bannerUrl={props.form.design?.banner?.url}>
            {showFormLanguages && (
                <>
                    <FormLanguages languages={props.languages} />

                    <Spacer height="4" />
                </>
            )}

            <FormEmail
                description={props.form.description}
                emailTitle={props.form.emailTitle}
                isOpen={props.isPreview || step === 0}
                isPreview={props.isPreview}
                onSuccess={onEmailSuccess}
            />

            {showProfiles && (
                <>
                    <Spacer height="4" />

                    <FormBox
                        canSelect={step >= 1 && step !== numberOfSteps - 1}
                        isCompleted={step >= 1}
                        isOpen={props.isPreview || step === 1}
                        number="2"
                        title={translate('choix_du_profil_50142')}
                        onSelect={() => setStep(1)}
                    >
                        <FormProfiles
                            userInfoId={userInfo?.id}
                            usersInfos={usersInfos}
                            onSuccess={onProfilesSuccess}
                        />
                    </FormBox>
                </>
            )}

            {hasAccess && !(props.isPreview && props.formPreviewState === 'blocked') ? (
                <FinalForm
                    align="center"
                    direction="column"
                    hideErrors={true}
                    initialValues={initialValues}
                    render={({ form, handleSubmit, values }) => (
                        <>
                            <Spacer height="4" />

                            <FormBox
                                canSelect={
                                    step >= informationsStepIndex && step !== numberOfSteps - 1
                                }
                                isCompleted={step >= informationsStepIndex + 1}
                                isOpen={props.isPreview || step === informationsStepIndex}
                                number={informationsStepIndex + 1}
                                title={
                                    isNonEmptyString(props.form.informationTitle)
                                        ? props.form.informationTitle
                                        : translate('informations_pe_37892')
                                }
                                onSelect={() => setStep(informationsStepIndex)}
                            >
                                <FormInformations
                                    change={form.change}
                                    countryCode={props.countryCode}
                                    elements={props.form.elements}
                                    handleSubmit={handleSubmit}
                                    isFinalStep={
                                        !showSlots &&
                                        !showMissions &&
                                        !showAccreditations &&
                                        !showTeamSummary
                                    }
                                    registerButtonText={registerButtonText}
                                    renderText={renderText}
                                    values={values.formRegister.userInfo.fields}
                                />
                            </FormBox>

                            <Spacer height="4" />

                            {showSlots && (
                                <>
                                    <FormBox
                                        canSelect={
                                            step >= slotsStepIndex && step !== numberOfSteps - 1
                                        }
                                        isCompleted={step >= slotsStepIndex + 1}
                                        isOpen={props.isPreview || step === slotsStepIndex}
                                        number={slotsStepIndex + 1}
                                        title={
                                            isNonEmptyString(props.form.slotTitle)
                                                ? props.form.slotTitle
                                                : translate('disponibilit_s_49923')
                                        }
                                        onSelect={() => setStep(slotsStepIndex)}
                                    >
                                        <FormSlotsLoader
                                            change={form.change}
                                            currentSlots={values.formRegister.slots}
                                            eventId={eventId}
                                            form={props.form}
                                            formId={formId}
                                            handleSubmit={handleSubmit}
                                            isFinalStep={!showMissions && !showAccreditations}
                                            organizationId={organizationId}
                                            registerButtonText={registerButtonText}
                                            renderText={renderText}
                                            userInfoFields={values.formRegister.userInfo.fields}
                                        />
                                    </FormBox>

                                    <Spacer height="4" />
                                </>
                            )}

                            {showMissions && (
                                <>
                                    <FormBox
                                        canSelect={
                                            step >= missionsStepIndex && step !== numberOfSteps - 1
                                        }
                                        isCompleted={step >= missionsStepIndex + 1}
                                        isOpen={props.isPreview || step === missionsStepIndex}
                                        number={missionsStepIndex + 1}
                                        title={
                                            isNonEmptyString(props.form.positionTitle)
                                                ? props.form.positionTitle
                                                : translate('missions_souhai_23225')
                                        }
                                        onSelect={() => setStep(missionsStepIndex)}
                                    >
                                        <FormMissionsLoader
                                            change={form.change}
                                            eventId={eventId}
                                            form={props.form}
                                            handleSubmit={handleSubmit}
                                            isFinalStep={!showAccreditations}
                                            positionsCategoriesIds={
                                                values.formRegister.positionsCategoriesIds
                                            }
                                            positionsIds={values.formRegister.positionsIds}
                                            positionsSlotsIds={
                                                values.formRegister.positionsSlotsIds
                                            }
                                            registerButtonText={registerButtonText}
                                            renderText={renderText}
                                            slots={values.formRegister.slots}
                                            userInfoFields={values.formRegister.userInfo.fields}
                                        />
                                    </FormBox>

                                    <Spacer height="4" />
                                </>
                            )}

                            {showAccreditations && (
                                <>
                                    <FormBox
                                        canSelect={
                                            step >= accreditationsStepIndex &&
                                            step !== numberOfSteps - 1
                                        }
                                        isCompleted={step >= accreditationsStepIndex + 1}
                                        isOpen={props.isPreview || step === accreditationsStepIndex}
                                        number={accreditationsStepIndex + 1}
                                        title={
                                            isNonEmptyString(props.form.accreditationTitle)
                                                ? props.form.accreditationTitle
                                                : translate('accr_ditations_74375')
                                        }
                                        onSelect={() => setStep(accreditationsStepIndex)}
                                    >
                                        <FormAccreditationsLoader
                                            accreditationsSlotsIds={
                                                values.formRegister.accreditationsSlotsIds
                                            }
                                            change={form.change}
                                            eventId={eventId}
                                            form={props.form}
                                            handleSubmit={handleSubmit}
                                            registerButtonText={registerButtonText}
                                            renderText={renderText}
                                            slots={values.formRegister.slots}
                                            userInfoFields={values.formRegister.userInfo.fields}
                                        />
                                    </FormBox>

                                    <Spacer height="4" />
                                </>
                            )}

                            {showTeamSummary && (
                                <>
                                    <FormBox
                                        canSelect={
                                            step >= teamSummaryStepIndex &&
                                            step !== numberOfSteps - 1
                                        }
                                        isCompleted={step >= numberOfSteps - 1}
                                        isOpen={props.isPreview || step === teamSummaryStepIndex}
                                        number={teamSummaryStepIndex + 1}
                                        title={translate('r_capitulatif_d_48468')}
                                        onSelect={() => setStep(teamSummaryStepIndex)}
                                    >
                                        <FormTeamSummary
                                            eventId={eventId}
                                            formId={formId}
                                            handleSummit={handleSubmit}
                                            registerButtonText={registerButtonText}
                                            slotDisplay={props.form.slotDisplay}
                                            teamCode={props.team!.teamCode}
                                        />
                                    </FormBox>

                                    <Spacer height="4" />
                                </>
                            )}

                            <FormBox
                                canSelect={false}
                                isCompleted={false}
                                isOpen={props.isPreview || step === numberOfSteps - 1}
                                number={numberOfSteps}
                                title={translate('confirmation_95122')}
                                onSelect={() => setStep(numberOfSteps - 1)}
                            >
                                <FormSuccess
                                    customFields={customFields}
                                    eventId={eventId}
                                    form={props.form}
                                    formId={formId}
                                    formRegisterResult={formRegisterResult}
                                    organizationId={organizationId}
                                    renderText={renderText}
                                    userInfoFields={values.formRegister.userInfo.fields}
                                />
                            </FormBox>

                            <Spacer height="9" />
                        </>
                    )}
                    validate={validate}
                    width={1}
                    onShowErrors={resetScroll}
                    onSubmit={onSubmit}
                />
            ) : (step === 0 || (step === 1 && showProfiles)) &&
              !(props.isPreview && props.formPreviewState === 'blocked') ? null : (
                <>
                    <FormNoAccess
                        noAccessMessage={props.form.noAccessMessage}
                        noAccessTitle={props.form.noAccessTitle}
                    />

                    <Spacer height="9" />
                </>
            )}
        </FormContainer>
    );
};

export const Form = () => {
    const { organizationId, eventId, formId, teamCode } = useParams<{
        teamCode: Emptyable<TeamCode>;
    }>();
    const { data, loader } = useFormInfosQuery({ organizationId, eventId, formId, teamCode });
    const { isPreview, formPreviewState, hideFormLanguages } = React.useMemo(() => {
        const searchParams = new URLSearchParams(location.search);

        return {
            isPreview: searchParams.get('preview') === 'true',
            formPreviewState: searchParams.get('state') as FormPreviewState,
            hideFormLanguages: searchParams.get('hideFormLanguages') === 'true'
        };
    }, []);

    React.useEffect(() => {
        if (data.organization) {
            addCustomTheme(data.organization.form.design?.colors);
        }
    }, [data.organization]);

    if (loader) {
        return loader;
    } else if (!data.organization.form.isOpen || (isPreview && formPreviewState === 'closed')) {
        return (
            <FormClosed
                bannerUrl={data.organization.form.design?.banner?.url}
                closedMessage={data.organization.form.closedMessage}
                closedTitle={data.organization.form.closedTitle}
            />
        );
    } else {
        return (
            <FormContent
                countryCode={data.organization.event?.country ?? data.organization.country}
                event={data.organization.event}
                form={data.organization.form}
                formPreviewState={formPreviewState}
                hideFormLanguages={hideFormLanguages}
                isPreview={isPreview}
                languages={data.organization.event?.languages ?? []}
                team={data.organization?.event?.team}
            />
        );
    }
};
