import { Blank } from 'common-front/src/components/blank/blank';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import {
    CustomFieldWithConditionFragment,
    EventId,
    FormId,
    FormInfosQuery,
    FormRegisterResult,
    OrganizationId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { Fields } from 'common/src/vo/field';
import * as React from 'react';
import { FormSuccessInvitations } from './formSuccessInvitations';

interface IFormSuccessProps {
    customFields: CustomFieldWithConditionFragment[];
    eventId: Emptyable<EventId>;
    form: FormInfosQuery['organization']['form'];
    formId: FormId;
    formRegisterResult: Emptyable<FormRegisterResult>;
    organizationId: OrganizationId;
    userInfoFields: Fields;

    renderText(message: string, fields: Fields): string;
}

export const FormSuccess = (props: IFormSuccessProps) => {
    const translate = useTranslate();

    return (
        <>
            {isNonEmptyString(props.form.successMessage) ? (
                <RichText
                    text={props.renderText(props.form.successMessage, props.userInfoFields)}
                />
            ) : (
                <Box height={250} width={1}>
                    <Blank imageSrc={Svgs.Done} title={translate('merci_70020')} />
                </Box>
            )}

            {props.form.areTeamsAllowed && (
                <FormSuccessInvitations
                    eventId={props.eventId}
                    form={props.form}
                    formId={props.formId}
                    formRegisterResult={props.formRegisterResult}
                    organizationId={props.organizationId}
                />
            )}
        </>
    );
};
