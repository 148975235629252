import { FormErrors } from 'common-front/src/components/form/formErrors';
import { Button } from 'common-front/src/designSystem/components/button';
import { FormMissions } from 'common-front/src/forms/missions/formMissions';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    FormInfosQuery,
    PositionId,
    PositionsCategoryId,
    PositionsSlotId,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { Fields } from 'common/src/vo/field';
import * as React from 'react';
import { useFormMissionsQuery } from '../../../../generated/graphqlHooks';

interface IFormMissionsLoaderProps {
    eventId: EventId;
    form: FormInfosQuery['organization']['form'];
    isFinalStep: boolean;
    positionsCategoriesIds: PositionsCategoryId[];
    positionsIds: PositionId[];
    positionsSlotsIds: PositionsSlotId[];
    registerButtonText: string;
    slots: VolunteersRegistrationsSlotInput[];
    userInfoFields: Fields;

    change(name: string, value: any): void;
    handleSubmit(): void;
    renderText(message: string, fields: Fields): string;
}

export const FormMissionsLoader = (props: IFormMissionsLoaderProps) => {
    const translate = useTranslate();
    const { data, loader } = useFormMissionsQuery({
        eventId: props.eventId
    });

    return (
        loader || (
            <>
                {isNonEmptyString(props.form.positionSubtitle) ? (
                    <RichText
                        text={props.renderText(props.form.positionSubtitle, props.userInfoFields)}
                    />
                ) : (
                    <>
                        <Box font="gray800 textMd semiBold">
                            {translate('s_lectionnez_vo_83925')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500">{translate('s_lectionnez_le_37648')}</Box>
                    </>
                )}

                <Spacer height="6" />

                <FormErrors />

                <FormMissions
                    change={props.change}
                    event={data.event}
                    options={props.form}
                    positionsCategoriesIds={props.positionsCategoriesIds}
                    positionsIds={props.positionsIds}
                    positionsSlotsIds={props.positionsSlotsIds}
                    prefix="formRegister."
                    slots={props.slots}
                    userInfoFields={props.userInfoFields}
                />

                <Spacer height="7" />

                <Flex>
                    <Button onClick={props.handleSubmit}>
                        {props.isFinalStep ? props.registerButtonText : translate('suivant_62774')}
                    </Button>
                </Flex>
            </>
        )
    );
};
